import React from 'react'
import './Footer.css'
import { AiFillInstagram, AiFillFacebook } from 'react-icons/ai';
import { useLocation } from 'react-router';

const Footer = () => {

  const location = useLocation();

  const provideDynamicMargin = () => {
    switch (location.pathname) {
      case "/":
        return "-150px"
      case "/support":
        return "-110px"
      case "/partners":
        return "-100px"
      default:
        return "-150px"
    }
  }

    return (
        <div className="footer-container" style={{marginTop: provideDynamicMargin()}}>
        <div className="triangle"> 
          <div className="triangle2" />
            <div className="footer-cta-container">
                <h3 className="footer-cta-text">Follow Us</h3>
                <div style={{marginTop: "0.6rem"}}>
                  <a href="https://reactjs.org/docs/getting-started.html"  target="_blank" rel="noopener noreferrer"><AiFillFacebook size={"1.7rem"} style={{marginRight: "0.6rem"}} color="white"/></a>
                  <a href="https://reactjs.org/docs/getting-started.html"  target="_blank" rel="noopener noreferrer"><AiFillInstagram size={"1.7rem"} color="white"/></a>
                </div>
          </div>
        </div>
      </div>
    )
}

export default Footer
