import React, { useState } from 'react'
import { stack as Menu } from 'react-burger-menu'
import './Burger.css'
import { AiFillHome } from 'react-icons/ai';
import { FaHandshake } from 'react-icons/fa';
import { TiInfoLarge } from 'react-icons/ti';
import { Link } from "react-router-dom";


const Burger = () => {

  const [showMenu, setShowMenu] = useState(false)

    return (
        <Menu 
        right 
        isOpen={showMenu} 
        onStateChange={(state)=>{
          setShowMenu(state.isOpen)
        }}
        >
        <Link className="menu-item" to="/" onClick={()=>setShowMenu(false)}><AiFillHome color="white" size="2rem" style={{marginRight: "1rem"}}/><span className="link-text">Home</span></Link>
        <Link className="menu-item" to="/support"  onClick={()=>setShowMenu(false)}><TiInfoLarge color="white" size="2rem" style={{marginRight: "1rem"}}/><span className="link-text">Support</span></Link>
        <Link className="menu-item" to="/partners" onClick={()=>setShowMenu(false)}><FaHandshake color="white" size="2rem" style={{marginRight: "1rem"}}/><span className="link-text">Partners</span></Link>
      </Menu>
    )
}

export default Burger
