import React, { useContext } from 'react'
import ReactModal from 'react-modal'
import VybLogo from '../../assets/images/vyb-logo.png'
import Button from '../Button/Button'
import { AgeContext } from '../Context/AgeContext'

const WelcomeModal = () => {

    ReactModal.setAppElement('body')

    const [showConsentModal, setShowConsentModal] = useContext(AgeContext)
    return (
        <ReactModal
        isOpen={showConsentModal}
        contentLabel="Minimal ReactModal Example"
        style={{
          overlay: {
            zIndex: 9999,
            backgroundColor: 'rgba(0,0,0,0.5)',
          },
          content: {
           top: '50%',
           left: '50%',
           right: 'auto',
           bottom: 'auto',
           marginRight: '-50%',
           transform: 'translate(-50%, -50%)',
           width: '70%',
           display: 'flex',
           flexDirection: 'column',
           alignItems: 'center'
          }
        }}
     >
      <img src={VybLogo} alt="Vyb Logo" className="nav-logo"/>
      <p style={{textAlign: 'center', marginBlock: '1rem'}}>Vyb products contain nicotine and are unsuitable for minors. Are you 18 years old or above?</p>
      <div style={{
        display: 'flex',
      }}>
       
      <Button
       buttonStyle={{
        marginRight: '0.5rem'
    }}
      onClick={()=>{
        console.log('no');
    }}
      >No</Button>
       <Button 
     
      onClick={()=>{
          setShowConsentModal(false)
      }}
      >Yes</Button>
      </div>
     </ReactModal>
    )
}

export default WelcomeModal
