import React from 'react'
import './Support.css'

const Support = () => {
    return (
        <div className="support-wrapper">
            <h1>User Agreement</h1>
            <div className="agreement-text-container">
            <p className="agreement-text"><span className="capitalize bold">Warning:</span> This product contains Nicotine. Nicotine is as addictive substance. Minors, (please check local laws and regulation regarding applicable age to use) pregnant, and/or lactating women, individuals with respiratory illnesses and those with allergies <span className="capitalize bold">cannot</span> use this product. If you are not smoking, we highly discourage the use of the product.</p>
            <p className="agreement-text">Keep out of reach of children. Please make sure that this product is kept away from reach of children. Excessive amounts of Nicotine is toxic. In case of accidental ingestion, drink plenty of water to dilute the nicotine. Consult a physician immediately. If the liquid gets in contact with skin or eyes, use water to rinse the affected area immediately. If irritation occurs, seek medical assistance. Make sure that to bring the product when seeking medical help.</p>
            <p className="agreement-text">If you experience any side effects in using this product, discontinue use immediately.</p>
            <p className="agreement-text">Opening or disassembling the unit and cartridge is prohibited. This product is designed to be ready for use without assembly. Tampering with this product and its contents voids the warranty. Use of the product after tampering may result in injury.</p>
            <p className="agreement-text">Use of this product outside of its designed purpose may produce adverse effects and injuries. Please use the device as intended.</p>
            <p className="agreement-text bold">Product Handling and Safety</p>
            <p className="agreement-text">This is a consumer electronic product containing a battery and other sensitive electronic. The device will be damaged if it is punctured, immersed or mixed with liquid or extremely dented. Discontinue use if there is evidence of damage. </p>
            <p className="agreement-text">This device uses Lithium-Ion batteries. Do not place the device near direct heat, prolonged direct exposure to sunlight, or in extremely hot environments. If the battery is physically damaged, avoid contact with skin and eyes and dispose immediately. Lithium-Ion batteries contain toxic chemicals that are harmful when made in contact with the skin and eyes. If contents of the battery are spilled on the skin, rinse with water immediately and seek medical attention. </p>
            <p className="agreement-text">Please be mindful of charging the device, make sure to use the accompanying charger and to not leave the device charging for long periods of time without anyone paying attention. If you will not use the device for a long time, please make sure to charge the battery every six months. If charging fails, the battery may have degraded and it is suggested to dispose of it properly and not use it again. </p>
            <p className="agreement-text">Always keep the device in a cool, dark, and dry place. </p>
            <p className="agreement-text">The cartridge has a clear casing that lets the user see the liquid contents. Please discontinue use once the cartridge is empty and replace the cartridge with a new one. </p>
            <p className="agreement-text"><span className="capitalize bold">Do not use a damaged device.</span> If you see any signs of damage, please dispose of the unit immediately and properly.
 </p>
            </div>
        </div>
    )
}

export default Support
