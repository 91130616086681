import React from 'react'
import Home from '../../assets/icons/home.svg'
import VybLogo from '../../assets/images/vyb-logo.png'
import HomeIcon from '../Icons/HomeIcon'
import Burger from './Burger'
import './NavBar.css'
import { AiFillHome } from 'react-icons/ai';
import { FaHandshake } from 'react-icons/fa';
import { TiInfoLarge } from 'react-icons/ti';
import { useLocation } from 'react-router'



const NavBar = () => {

    const location = useLocation();

    const navIcon = () => {
        switch(location.pathname) {
            case "/":
                return <AiFillHome size="3rem" style={{marginLeft: "1rem"}}/>
            case "/support":
                return <TiInfoLarge size="3rem" style={{marginLeft: "1rem"}}/>
            case "/partners":
                return <FaHandshake size="3rem" style={{marginLeft: "1.6rem"}}/>
            default:
                return <AiFillHome size="3rem" style={{marginLeft: "1rem"}}/>
             }
        }

        const navPlaceholder = () => {
            switch(location.pathname) {
                case "/":
                    return <AiFillHome size="3rem" color="white" style={{marginRight: "1rem"}}/>
                case "/support":
                    return <TiInfoLarge size="3rem" color="white" style={{marginRight: "1rem"}}/>
                case "/partners":
                    return <FaHandshake size="3rem" color="white" style={{marginRight: "1.6rem"}}/>
                default:
                    return <AiFillHome size="3rem" color="white" style={{marginRight: "1rem"}}/>
                 }
            }

    console.log('my location', location);
    return (
        <div className="nav-container">
           {navIcon()}
           <img src={VybLogo} alt="Vyb Logo" className="nav-logo"/>
           {navPlaceholder()}
        </div>
    )
}

export default NavBar
