import './reset.css';
import './App.css';
import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useLocation,
} from "react-router-dom";
import Home from './components/Home/Home';
import Support from './components/Support/Support';
import Partners from './components/Partners/Partners';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import NavBar from './components/NavBar/NavBar';
import Burger from './components/NavBar/Burger';
import Footer from './components/Footer/Footer';
import { use100vh } from 'react-div-100vh'
import ReactModal from 'react-modal';
import { useEffect, useState } from 'react';
import { FaRegIdBadge } from 'react-icons/fa';
import VybLogo from './assets/images/vyb-logo.png'
import WelcomeModal from './components/Modal/WelcomeModal';
import { AgeContext } from './components/Context/AgeContext';

function App() {

const routes = [
  { path: '/', name: 'Home', Component: Home },
  { path: '/support', name: 'Support', Component: Support },
  { path: '/partners', name: 'Partners', Component: Partners },
]

const {Provider} = AgeContext

const height = use100vh();

const [showConsentModal, setShowConsentModal] = useState(true)

console.log('show modal', showConsentModal);

  return (
    <Provider value={[showConsentModal, setShowConsentModal]}>
    <BrowserRouter>
      <div className="app" style={{minHeight: height}}>
        <Burger />
        <NavBar />
            <TransitionGroup component={null}>
              <Switch>
                {routes.map(({ path, Component }) => (
                  <Route key={path} exact path={path}>
                    {({ match }) => (
                    <CSSTransition
                      in={match != null}
                      timeout={300}
                      classNames="fade"
                      unmountOnExit
                    >
                    <div className="fade">
                      <Component />
                    </div>
                    </CSSTransition>
                 )}
                  </Route>
                ))}
              </Switch>
           </TransitionGroup>
            <Footer />
          </div>
         <WelcomeModal />
    </BrowserRouter>
         </Provider>
  );
}

export default App;
