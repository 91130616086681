import React, { useState } from 'react'
import './Home.css'
import blackVyb from '../../assets/images/black-vyb.jpg'
import redVyb from '../../assets/images/red-vyb.jpg'
import greenVyb from '../../assets/images/green-vyb.jpg'
import silverVyb from '../../assets/images/silver-vyb.jpg'
import goldVyb from '../../assets/images/gold-vyb.jpg'
import specs from '../../assets/images/specs.jpg'

const Home = () => {

    const [imageUrl, setImageUrl] = useState(blackVyb)

    const colorArray = [
        {
            color: 'black',
            url: blackVyb,
        },
        {
            color: 'red',
            url: redVyb,
        },
        {
            color: 'green',
            url: greenVyb,
        },
        {
            color: 'silver',
            url: silverVyb,
        },
        {
            color: 'gold',
            url: goldVyb,
        },
    ]
    return (
        <div className="home-container">
            <h1>Our Product</h1>
            <div className="product-container">
                <div className="image-wrapper">  
                     <img 
                        key={imageUrl}
                        src={imageUrl} 
                        alt="Pod System" 
                        className="product-image"/>    
                        <img 
                        src={specs} 
                        alt="Specification" 
                        className="specs-image"/>   
                    </div>   
            {/* <h3>Pod System Kit</h3> */}
            <div 
            style={{
                display: 'flex',
                width: '14rem',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
            {!!colorArray && colorArray.map(({color, url})=>(
                <div 
                className={url===imageUrl ? "color-icon expanded" : "color-icon"}
                key={url}
                onClick={()=>setImageUrl(url)}
                style={{
                    backgroundColor: color,
                    borderRadius: '2rem',
                }}/>
            ))}
            </div>
            </div>
            <div className="specification-container">
            <p>Designed by vapers for vapers. Vyb is the culmination of years of research and development to deliver the best vaping experience. Our unique E-liquid blends are handcrafted by expert brewers with years of product experience.</p>
            </div>
        </div>
    )
}

export default Home
