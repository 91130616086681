import React, { useEffect, useState } from 'react'
import { Sentry } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import './Partners.css'
import {CSSTransition, TransitionGroup} from "react-transition-group"
import { AiFillMail } from 'react-icons/ai'


const Partners = () => {
    
    const [partnersData, setPartnersData] = useState(null);

    const partnersDataCall = async () => {
        await (await fetch("/.netlify/functions/getPartnersData/getPartnersData.js"))
          .json()
          .then(data => setPartnersData(data))
          .catch(console.error);
      };

    useEffect(() => {
        partnersDataCall();
    }, [])

    return (
        <div className="partners">
            <h1>Our Partners</h1>
            {!partnersData && (
                <div className="indicator-wrapper">
                    <Sentry color="black" size={70} speed={0.5}/>
                </div>
            )}
            <TransitionGroup component={null}>
            <div className="partners-wrapper">
            {!!partnersData && partnersData.records.map(({fields})=>(
                <CSSTransition
                in={!!partnersData}
                classNames="fade"
                >
                <div className="partners-container">
                    <img src={fields.image} alt={fields.alt} className="partners-image"/>
                    <div className="text-container">
                    <h2 className="partners-h2">{fields.name}</h2>
                    <p className="partners-description">{fields.description}</p>
                    </div>
                </div>
                </CSSTransition>
            ))}
            </div>
            </TransitionGroup>
            <a href="mailto:vybtech@yahoo.com" className="partners-container mail-container">
                <AiFillMail style={{marginRight: '0.5rem'}}/>
                <h5>partners@myvyb.net</h5>
            </a>
        </div>
    )
}

export default Partners
