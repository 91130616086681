import React from 'react'

const Button = ({children, onClick, buttonStyle}) => {
    return (
        <button onClick={onClick} style={{
            width: '5rem',
            backgroundColor: '#FFFFFF',
            borderRadius: '5px',
            borderColor: 'rgba(0,0,0,0.5)',
            paddingBlock: '0.2rem',
            boxShadow: '3px 3px 8px rgba(160, 160, 160, 0.4)',
            ...buttonStyle
        }}>
            <h6>
            {children}
            </h6>
        </button>
    )
}

export default Button
